body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

a{
    text-decoration: none;
}

.bck_darkblue
{
    background-color: #162336
}

.bck_blue{
    background-color: #005773
}

.bck_lightblue{
    background-color: #6399AB
}

.bck_red{
    background-color: #cc171d
}

.bck_color{
    background-color: #f2f2f2
}

.icon_bck_light {
    background-color: #5683A5
}

.icon_bck_dark {
    background-color: #0a1c2d
}

h1{
    text-align: center;
    text-transform: uppercase;
    font-size: 82px;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
}

h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 45px;
    border-left: 5px solid #cc171d;
    text-align: left;
    padding-left: 15px;
}

.h2login{
    left: -24px;
    position: relative;
}

h3{
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    border: 5px solid #cc171d;
    text-align: left;
}

p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.welcome_container{
    width: 500px;
    left: 100px;
    top: 200px;
    text-align: center;
    position: relative;
}

.layout {
    width: 'auto';
}

.paper {
    height:800px;
    width:1200px;
    overflow: hidden;
}

.featured_stripes {
    position: relative;
}

.featured_stripes .stripe {
    width: 950px;
    height: 500px;
    position: absolute;
    -webkit-box-shadow: 1px 1px 35px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 35px 5px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 35px 5px rgba(0,0,0,0.75);
}

.center_container{
    text-align: center;
}

.dots{
    background-color: #162336;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    text-align: center;
    z-index: 1;
    position: relative;
    -webkit-box-shadow: -1px 11px 19px -9px rgba(0,0,0,1);
    -moz-box-shadow: -1px 11px 19px -9px rgba(0,0,0,1);
    box-shadow: -1px 11px 19px -9px rgba(0,0,0,1);
}

.active_dot{
    width: 17px;
    height: 17px;
    left: 4px;
    top: 4px;
    position: relative;
    background-color: rgb(204, 23, 29);
    border-radius: 100%;


}

.media_icon_container{
    height: 70px;
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.profile_buttons_container{
    position: absolute;
    z-index: 2;
    top: 3px;
    left:  10px;
}

.center{
    text-align: center;
}

.stepButton {
    background-color: '#005773';
    color: 'white';
    font-weight: 500;
}

.stepButton .left{
    left: '50px';
}

.stepButton .right{
    right: '50px';
    float: 'right';
}

.login_input_container{
    text-align: center;
    /* padding-left: 30px;
    padding-right: 30px; */
    margin-left: 30px;
    margin-right: 30px;
}

.scrollbar{
    right: 1px;
    position: relative;
    background: #005773 !important;
}

.scrollarea .scrollbar-container.vertical {
    right: -2px !important;
    z-index: 5;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }